import { DataGrid, GridRenderCellParams, ukUA } from '@mui/x-data-grid';
import * as React from 'react';
import moment from 'moment';
import MenuIcon from '@mui/icons-material/Menu';
import { style } from '../../../../common/DataTable/style';
import styles from './styles.module.scss';
import { InvoiceDetailsModal } from './InvoiceDetailsModal';
import useModal from '../../../../shared/hooks/useModal/useModal';

interface Props {
  data: any;
}

export const InvoicesReportTable: React.FC<Props> = ({ data }) => {
  const [pageSize, setPageSize] = React.useState(50);
  const [selectedRow, setSelectedRow] = React.useState<any>();
  const { isShow, openModal, closeModal } = useModal();

  return (
    <div style={{ height: 580, width: '100%', marginTop: '20px' }}>
      <DataGrid
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
        rows={data}
        headerHeight={34}
        rowHeight={34}
        columns={[
          { field: 'number', headerName: 'Номер накладної', width: 180 },
          { field: 'boxesCount', headerName: 'Кількість ящиків, шт', width: 160 },
          {
            field: 'createDate',
            headerName: 'Дата і час створення',
            minWidth: 160,
            flex: 1,
            renderCell: (params: GridRenderCellParams<Date>) => (
              <span>{moment(params.row.createDate).format('DD-MM-YYYY HH:mm:ss')}</span>
            ),
          },
          {
            field: 'button',
            headerName: 'Меню',
            headerAlign: 'center',
            width: 60,
            align: 'center',
            renderCell: (params: GridRenderCellParams<Date>) => (
              <MenuIcon
                className={styles.icon}
                onClick={() => {
                  setSelectedRow({ documentShippingId: params.row.id });
                  openModal();
                }}
              />
            ),
          },
        ]}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        sx={style}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        componentsProps={{
          pagination: {
            SelectProps: {
              MenuProps: {
                sx: {
                  '& .MuiMenuItem-root': {
                    fontSize: 14,
                    bgcolor: '#ffffff',
                  },
                },
                PaperProps: {
                  sx: {
                    bgcolor: '#ffffff',
                  },
                },
              },
            },
          },
        }}
      />

      <InvoiceDetailsModal isShown={isShow} closeModal={closeModal} params={selectedRow} />
    </div>
  );
};
